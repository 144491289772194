import React from "react";

import { cn } from "@/lib/utils";

type CardBenefitProps = {
    title: React.ReactNode;
    subtitle: string | undefined;
    icon: JSX.Element;
    className?: string;
};

const CardBenefit: React.FC<CardBenefitProps> = ({ title, subtitle, icon, className }) => {
    return (
        <div className={cn("CardBenefit | flex gap-[12px]", className)}>
            <div className="relative h-[32px] max-h-[32px] min-h-[32px] w-[32px] min-w-[32px] max-w-[32px]">{icon}</div>
            <div className="mt-[8px] flex w-full flex-col gap-[8px]">
                <div className="font-frutigerBlackItalic text-[16px] uppercase leading-[110%] text-neutral-600">
                    {title}
                </div>
                <div
                    className={cn(
                        "font-frutigerRoman text-[12px] normal-case leading-[135%] text-neutral-300",
                        "lg:text-[16px]",
                    )}
                >
                    {subtitle}
                </div>
            </div>
        </div>
    );
};

export default CardBenefit;
