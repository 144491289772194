import { cn } from "@/lib/utils";

type CardRisksProps = {
    id: number;
    title: string;
    icon: React.ReactNode;
    className?: string;
    activeTab: number;
    setActiveTab: (id: number) => void;
};

const CardRisks: React.FC<CardRisksProps> = ({ id, title, icon, activeTab, setActiveTab, className }) => {
    return (
        <button
            onClick={() => setActiveTab(id)}
            className={cn(
                "CardRisks | ",
                "flex items-center gap-3 rounded-tl-[10px] rounded-tr-[10px] border-b-0 border-l border-r border-t border-blackShade-15 px-4 py-3",
                "font-frutigerRoman text-[16px] font-normal text-neutral-900",
                activeTab === id && "border-neutral-200 bg-neutral-200 font-frutigerBlack text-red-primary",
                className,
            )}
        >
            {title}
            <div className="relative h-[16px] max-h-[16px] min-h-[16px] w-[16px] min-w-[16px] max-w-[16px]">{icon}</div>
        </button>
    );
};

export default CardRisks;
