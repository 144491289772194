import * as React from "react";

import { cn } from "@/lib/utils";

type CardWhiteProps = {
    children: React.ReactNode;
    className?: string;
};

const CardWhite: React.FC<CardWhiteProps> = ({ children, className }) => {
    return (
        <div
            className={cn(
                "CardWhite | ",
                "flex w-full flex-col gap-[24px] rounded-[24px] border border-blackShade-15 bg-white px-[24px] pb-[32px] pt-[24px] shadow-card-elements",
                className,
            )}
        >
            {children}
        </div>
    );
};

export default CardWhite;
