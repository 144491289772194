import store from "stores/store";
import CardWhite from "../cards/CardWhite";
import CardHeader from "../cards/CardHeader";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import CardRisks from "../cards/CardRisks";
import Button from "../buttons/Button";
import CardRisksMobile from "../cards/CardRisksMobile";
import { useContext, useState } from "react";
import { cn, useLG } from "@/lib/utils";
import Markdown from "react-markdown";
import ModalLocal from "../modals/ModalLocal";
import { TrackingContext } from "@/components/shared/tracking/Tracking";

const CardFour = () => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const [activeTab, setActiveTab] = useState<number>(0);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const LG = useLG();

    const { trackCTA } = useContext(TrackingContext);

    return (
        <>
            <CardWhite className="lg:p-[32px]">
                <CardHeader
                    title={startLocalized?.cardFourTitle.text}
                    content={startLocalized?.cardFourContent}
                    icon={
                        <ExportedImage
                            src={
                                startLocalized?.cardFourTitle.icon !== undefined &&
                                startLocalized?.cardFourTitle.icon !== null
                                    ? getImageUrlFromGeneralImage(startLocalized.cardFourTitle.icon)
                                    : "No image provided"
                            }
                            alt={
                                startLocalized?.cardFourTitle.icon !== undefined &&
                                startLocalized?.cardFourTitle.icon !== null
                                    ? getAltTextFromGeneralImage(startLocalized.cardFourTitle.icon)
                                    : "No image provided"
                            }
                            layout="fill"
                            objectPosition="center"
                        />
                    }
                />
                <div className="flex flex-col gap-[16px] font-frutigerBlackItalic text-[16px] uppercase leading-[110%] text-neutral-600">
                    {startLocalized?.cardFourSubtitle}
                    <div>
                        {!LG && (
                            <div className="flex flex-col">
                                {startLocalized?.cardFourAccordions.map(
                                    (accordion, index) =>
                                        accordion !== undefined &&
                                        accordion !== null && (
                                            <CardRisksMobile
                                                key={index}
                                                id={index}
                                                title={accordion?.title}
                                                content={accordion.content}
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                                icon={
                                                    <ExportedImage
                                                        src={
                                                            accordion.titleIcon !== undefined &&
                                                            accordion.titleIcon !== null
                                                                ? getImageUrlFromGeneralImage(accordion.titleIcon)
                                                                : "No image provided"
                                                        }
                                                        alt={
                                                            accordion.titleIcon !== undefined &&
                                                            accordion.titleIcon !== null
                                                                ? getAltTextFromGeneralImage(accordion.titleIcon)
                                                                : "No image provided"
                                                        }
                                                        layout="fill"
                                                        objectPosition="center"
                                                    />
                                                }
                                            />
                                        ),
                                )}
                            </div>
                        )}
                        {LG && (
                            <div className="flex flex-col">
                                <div className="flex gap-[8px]">
                                    {startLocalized?.cardFourAccordions.map(
                                        (accordion, index) =>
                                            accordion !== undefined &&
                                            accordion !== null && (
                                                <CardRisks
                                                    key={index}
                                                    id={index}
                                                    title={accordion?.title}
                                                    activeTab={activeTab}
                                                    setActiveTab={setActiveTab}
                                                    icon={
                                                        <ExportedImage
                                                            src={
                                                                accordion.titleIcon !== undefined &&
                                                                accordion.titleIcon !== null
                                                                    ? getImageUrlFromGeneralImage(accordion.titleIcon)
                                                                    : "No image provided"
                                                            }
                                                            alt={
                                                                accordion.titleIcon !== undefined &&
                                                                accordion.titleIcon !== null
                                                                    ? getAltTextFromGeneralImage(accordion.titleIcon)
                                                                    : "No image provided"
                                                            }
                                                            layout="fill"
                                                            objectPosition="center"
                                                        />
                                                    }
                                                />
                                            ),
                                    )}
                                </div>
                                <div
                                    className={cn(
                                        "markdown-container rounded-bl-[8px] rounded-br-[8px] rounded-tr-[8px] bg-neutral-200 p-[32px] font-frutigerRoman text-[16px] font-normal normal-case not-italic leading-[135%] text-neutral-300",
                                        activeTab === 4 && "lg:rounded-tr-none xl:rounded-tr-[8px]",
                                    )}
                                >
                                    <Markdown>{startLocalized?.cardFourAccordions[activeTab]?.content ?? ""}</Markdown>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={cn("flex flex-col items-center gap-[24px]", "lg:items-start")}>
                    <Button
                        onClick={() => {
                            trackCTA("jetzt-beraten-lassen-2");
                            setModalOpen(!isModalOpen);
                        }}
                        className="md:w-[275px]"
                        rightIcon={
                            <ExportedImage
                                src={
                                    startLocalized?.cardFourButton.icon !== undefined &&
                                    startLocalized?.cardFourButton.icon !== null
                                        ? getImageUrlFromGeneralImage(startLocalized.cardFourButton.icon)
                                        : "No image provided"
                                }
                                alt={
                                    startLocalized?.cardFourButton.icon !== undefined &&
                                    startLocalized?.cardFourButton.icon !== null
                                        ? getAltTextFromGeneralImage(startLocalized.cardFourButton.icon)
                                        : "No image provided"
                                }
                                layout="fill"
                                objectPosition="center"
                            />
                        }
                    >
                        {startLocalized?.cardFourButton.title}
                    </Button>
                    <button
                        onClick={() => {
                            trackCTA("jetzt-online-abschliessen-2");
                            window.open(startLocalized?.cardFourButtonLink.link ?? "/", "_blank");
                        }}
                        className="flex items-center gap-[8px] font-frutigerBold text-[14px] leading-[110%] text-neutral-600"
                    >
                        {startLocalized?.cardFourButtonLink.title}
                        <div className="relative flex h-[24px] w-[24px] items-center justify-center">
                            <ExportedImage
                                src={
                                    startLocalized?.cardFourButtonLink.icon !== undefined &&
                                    startLocalized?.cardFourButtonLink.icon !== null
                                        ? getImageUrlFromGeneralImage(startLocalized.cardFourButtonLink.icon)
                                        : "No image provided"
                                }
                                alt={
                                    startLocalized?.cardFourButtonLink.icon !== undefined &&
                                    startLocalized?.cardFourButtonLink.icon !== null
                                        ? getAltTextFromGeneralImage(startLocalized.cardFourButtonLink.icon)
                                        : "No image provided"
                                }
                                layout="fill"
                                objectPosition="center"
                            />
                        </div>
                    </button>
                </div>
            </CardWhite>
            <ModalLocal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)} />
        </>
    );
};

export default CardFour;
