import { IconProps } from '@/components/web/icons/types';

const IconCheck: React.FC<IconProps> = ({
  width = '14',
  height = '10',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3334 1L5.00002 8.33333L1.66669 5'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconCheck;
