import { cn } from "@/lib/utils";

import { ButtonProps } from "@/components/shared/types";

const IconContainer: React.FC<{ icon?: JSX.Element }> = ({ icon }) => (
    <div className="relative flex h-[20px] max-h-[20px] min-h-[20px] w-[20px] min-w-[20px] max-w-[20px] items-center justify-center md:h-[24px] md:max-h-[24px] md:min-h-[24px] md:w-[24px] md:min-w-[24px] md:max-w-[24px]">
        {icon}
    </div>
);

const IconContainerLeft: React.FC<{ icon?: JSX.Element }> = ({ icon }) => (
    <div className="relative flex h-[20px] max-h-[20px] min-h-[20px] w-[20px] min-w-[20px] max-w-[20px] items-center justify-center md:h-[24px] md:max-h-[24px] md:min-h-[24px] md:w-[24px] md:min-w-[24px] md:max-w-[24px]">
        {icon}
    </div>
);

const Button: React.FC<ButtonProps> = ({
    children,
    className,
    variant = "primary",
    leftIcon,
    rightIcon,
    disabledIcon,
    disabled,
    ...rest
}) => {
    return (
        <button
            type="button"
            className={cn(
                "text-left font-frutigerBlackItalic text-[14px] uppercase leading-[100%]",
                "w-full rounded-full border-blackShade-15 px-[24px] py-[12px]",
                "md:text-[16px]",
                "active:bg-red-dark hover:bg-red-active focus:outline-none focus-visible:border-blue-focus focus-visible:ring-2 focus-visible:ring-blue-focus",
                [
                    variant === "primary" && !disabled && "bg-red-primary text-white shadow-red-button",
                    variant === "secondary" && !disabled && "bg-white text-red-primary shadow-white-button",
                ],
                [
                    leftIcon && "pl-[12px] pr-[24px] lg:pl-[12px] lg:pr-[24px]",
                    rightIcon && "pl-[24px] pr-[12px] lg:pl-[24px] lg:pr-[12px]",
                ],
                [disabled && "pointer-events-none cursor-not-allowed bg-neutral-400 text-neutral-300 shadow-none"],
                className,
            )}
            {...rest}
        >
            <div className="flex w-full items-center justify-between gap-[16px]">
                {leftIcon && !disabled && <IconContainerLeft icon={leftIcon} />}
                {leftIcon && disabled && <IconContainerLeft icon={disabledIcon} />}
                {children}
                {rightIcon && disabled && <IconContainer icon={disabledIcon} />}
                {rightIcon && !disabled && <IconContainer icon={rightIcon} />}
            </div>
        </button>
    );
};

export default Button;
