import ExportedImage from "next-image-export-optimizer";
import store from "stores/store";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import Button from "../buttons/Button";
import { cn } from "@/lib/utils";
import CardHeader from "../cards/CardHeader";
import ModalLocal from "../modals/ModalLocal";
import { useState } from "react";

const NoCardThree = () => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <div className="flex flex-col gap-[24px]">
                <CardHeader
                    title={startLocalized?.noCardThreeTitle.text}
                    icon={
                        <ExportedImage
                            src={
                                startLocalized?.noCardThreeTitle.icon !== undefined &&
                                startLocalized?.noCardThreeTitle.icon !== null
                                    ? getImageUrlFromGeneralImage(startLocalized.noCardThreeTitle.icon)
                                    : "No image provided"
                            }
                            alt={
                                startLocalized?.noCardThreeTitle.icon !== undefined &&
                                startLocalized?.noCardThreeTitle.icon !== null
                                    ? getAltTextFromGeneralImage(startLocalized.noCardThreeTitle.icon)
                                    : "No image provided"
                            }
                            layout="fill"
                            objectPosition="center"
                        />
                    }
                />
                <div
                    className={cn(
                        "flex flex-col gap-x-[16px] 2xs:grid 2xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-[30px]",
                    )}
                >
                    {startLocalized?.participatingSparkassen &&
                        startLocalized?.participatingSparkassen.map((image, index) => (
                            <button
                                key={index}
                                className={cn(
                                    "flex h-[60px] items-center justify-between gap-[8px] border-b-[1px] border-dark-200 text-left first:border-t-[1px]",
                                    index === 1 && "2xs:border-t-[1px]",
                                    index === 2 && "md:border-t-[1px]",
                                    index == 3 && "lg:border-t-[1px]",
                                )}
                                onClick={() => window.open(image?.linkHomepage ?? "/", "_blank")}
                            >
                                <div className="font-frutigerBold text-[14px] leading-[110%] text-black">
                                    {image?.title}
                                </div>
                                <div className="relative h-[24px] max-h-[24px] min-h-[24px] w-[24px] min-w-[24px] max-w-[24px]">
                                    <ExportedImage
                                        src={
                                            startLocalized?.participatingSparkassenListIcon !== undefined &&
                                            startLocalized?.participatingSparkassenListIcon !== null
                                                ? getImageUrlFromGeneralImage(
                                                      startLocalized?.participatingSparkassenListIcon,
                                                  )
                                                : "No image provided"
                                        }
                                        alt={
                                            startLocalized?.participatingSparkassenListIcon !== undefined &&
                                            startLocalized?.participatingSparkassenListIcon !== null
                                                ? getAltTextFromGeneralImage(
                                                      startLocalized?.participatingSparkassenListIcon,
                                                  )
                                                : "No image provided"
                                        }
                                        layout="fill"
                                        objectFit="cover"
                                    />
                                </div>
                            </button>
                        ))}
                </div>
                <div className="flex w-full justify-center">
                    <Button
                        onClick={() => setModalOpen(!isModalOpen)}
                        className="md:w-[400px]"
                        rightIcon={
                            <ExportedImage
                                src={
                                    startLocalized?.noCardThreeButton.icon !== undefined &&
                                    startLocalized?.noCardThreeButton.icon !== null
                                        ? getImageUrlFromGeneralImage(startLocalized?.noCardThreeButton.icon)
                                        : "No image provided"
                                }
                                alt={
                                    startLocalized?.noCardThreeButton.icon !== undefined &&
                                    startLocalized?.noCardThreeButton.icon !== null
                                        ? getAltTextFromGeneralImage(startLocalized?.noCardThreeButton.icon)
                                        : "No image provided"
                                }
                                layout="fill"
                                objectFit="cover"
                            />
                        }
                    >
                        {startLocalized?.noCardThreeButton.text}
                    </Button>
                </div>
            </div>
            <ModalLocal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(!isModalOpen)}
                checkedBox={startLocalized?.cardOneRadioButtonOne}
            />
        </>
    );
};

export default NoCardThree;
