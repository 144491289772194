import CardFour from "@/components/web/landingpage/CardFour";
import CardThree from "@/components/web/landingpage/CardThree";
import CardTwo from "@/components/web/landingpage/CardTwo";
import Hero from "@/components/web/heros/Hero";
import NoCardOne from "@/components/web/landingpage/NoCardOne";
import NoCardTwo from "@/components/web/landingpage/NoCardTwo";
import QandA from "@/components/web/landingpage/QandA";
import { inject, observer } from "mobx-react";
import store from "stores/store";
import NoCardThree from "@/components/web/landingpage/NoCardThree";
import Footer from "@/components/web/footer/Footer";
import { cn } from "@/lib/utils";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import { Tracking, TrackingContext } from "@/components/shared/tracking/Tracking";
import { useEffect, useRef, useState } from "react";
import Head from "next/head";
import clsx from "clsx";
import Markdown from "react-markdown";

type LandingProps = {
    onCall: () => void;
    onTutorial: () => void;
};

const Landing: React.FC<LandingProps> = ({ onCall, onTutorial }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized, metaLocalized } = store;
    const [shareViaCopy, setShareViaCopy] = useState<boolean>(false);

    async function sharePage() {
        if (navigator.share) {
            navigator
                .share({
                    title: "neue leben",
                    text: "Triff dein Zukunfts-Ich",
                    url: window.location.href,
                })
                .then(() => {
                    console.log("Danke fürs Teilen!");
                })
                .catch(console.error);
        } else {
            try {
                await navigator.clipboard.writeText(window.location.href);
                setShareViaCopy(true);
                console.log("Link copied to clipboard!");
                setTimeout(() => {
                    setShareViaCopy(false);
                }, 3000);
            } catch (error) {
                console.error("Error copying link:", error);
            }
        }
    }

    const [windowWidth, setWindowWidth] = useState(0);
    const [isLG, setLG] = useState<boolean>(false);

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        if (windowWidth < 1024) {
            setLG(false);
        } else {
            setLG(true);
        }

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth]);

    const scrollRef = useRef(null);

    const title = metaLocalized?.ogTitle && metaLocalized?.ogTitle.toString();
    const description = metaLocalized?.ogDescription && metaLocalized?.ogDescription.toString();

    return (
        <>
            <Head>
                <meta property="og:title" content={title ?? "Triff dein Zukunfts-Ich"} />
                <meta
                    property="og:description"
                    content={
                        description ??
                        "Triff dein Zukunfts-Ich in diesem Dialog durch die Zeit. Frag dein älteres Ich, wie die Zukunft aussieht, und hol dir gute Tipps fürs Heute, damit es dir in Zukunft gut geht! Starte jetzt deinen Anruf durch die Zeit!"
                    }
                />
                <meta property="og:url" content="https://triffdeinezukunft.de/" />
                <meta property="og:image" content="https://nl-assets.curious.zone/MetaTagImage.jpg" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title ?? "Triff dein Zukunfts-Ich"} />
                <meta
                    name="twitter:description"
                    content={
                        description ??
                        "Triff dein Zukunfts-Ich in diesem Dialog durch die Zeit. Frag dein älteres Ich, wie die Zukunft aussieht, und hol dir gute Tipps fürs Heute, damit es dir in Zukunft gut geht! Starte jetzt deinen Anruf durch die Zeit!"
                    }
                />
                <meta name="twitter:image" content="https://nl-assets.curious.zone/MetaTagImage.jpg" />
                <meta name="twitter:url" content="https://triffdeinezukunft.de/" />
            </Head>
            <Tracking scrollTrackContainerRef={scrollRef}>
                <div className="relative flex h-screen w-screen flex-col items-center overflow-y-auto" ref={scrollRef}>
                    <div className="relative top-[-1px] h-full w-full">
                        <Hero onCall={onCall} onTutorial={onTutorial} />
                    </div>
                    <div
                        className={cn(
                            "relative top-[159px] flex w-full flex-col items-center gap-[80px]",
                            "2xs:top-[77px]",
                            "md:top-[48px]",
                            "lg:top-[79px]",
                        )}
                    >
                        {/* <div
                            className={cn(
                                "relative flex h-full w-full max-w-[288px] flex-col gap-[80px]",
                                "2xs:max-w-[416px]",
                                "md:max-w-[704px]",
                                "lg:max-w-[912px]",
                                "xl:max-w-[918px]",
                            )}
                        > */}
                        <div
                            className={cn(
                                "relative flex h-full w-full flex-col gap-[80px] px-[16px]",
                                "2xs:px-[32px]",
                                "md:max-w-[704px] md:px-0",
                                "lg:max-w-[912px]",
                                "xl:max-w-[918px]",
                            )}
                        >
                            {/* <div
                            className={cn(
                                "relative flex h-full w-full flex-col items-center gap-[80px] px-[16px]",
                                "2xs:px-[32px]",
                                "lg:px-[56px]",
                                "xl:px-[181px]",
                                "4xl:px-[501px]",
                            )}
                        > */}
                            <NoCardOne />
                            <div className="lg:flex lg:flex-col lg:gap-[24px]">
                                <div className={cn("flex flex-col gap-[80px]", "lg:flex-row lg:gap-[30px]")}>
                                    <CardTwo className="lg:w-[539px] lg:min-w-[539px] lg:max-w-[539px]" />
                                    <div className={cn("flex flex-col gap-[80px]", "lg:w-[349px] lg:gap-[30px]")}>
                                        {/* {!LG && <CardThree />} */}
                                        <NoCardTwo />
                                        {!isLG ? (
                                            <div className="flex flex-col gap-[24px]">
                                                <CardThree className="lg:p-[32px]" />
                                                <Markdown
                                                    components={{
                                                        a: ({ node, ...props }) => {
                                                            if (props.href && props.href.startsWith("tel:")) {
                                                                return (
                                                                    <a {...props} className="tel-link">
                                                                        {props.children}
                                                                    </a>
                                                                );
                                                            }

                                                            return (
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    {...props}
                                                                    className="red-link"
                                                                >
                                                                    {props.children}
                                                                </a>
                                                            );
                                                        },
                                                    }}
                                                    className="font-frutigerRoman text-[12px] leading-[135%] text-neutral-300"
                                                >
                                                    {startLocalized?.cardThreeDisclaimer ?? ""}
                                                </Markdown>
                                            </div>
                                        ) : (
                                            <CardThree className="lg:p-[32px]" />
                                        )}
                                    </div>
                                </div>
                                {isLG && (
                                    <div className="flex w-full justify-end">
                                        <Markdown
                                            components={{
                                                a: ({ node, ...props }) => {
                                                    if (props.href && props.href.startsWith("tel:")) {
                                                        return (
                                                            <a {...props} className="tel-link">
                                                                {props.children}
                                                            </a>
                                                        );
                                                    }

                                                    return (
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            {...props}
                                                            className="red-link"
                                                        >
                                                            {props.children}
                                                        </a>
                                                    );
                                                },
                                            }}
                                            className="font-frutigerRoman text-[12px] leading-[135%] text-neutral-300 lg:max-w-[349px]"
                                        >
                                            {startLocalized?.cardThreeDisclaimer ?? ""}
                                        </Markdown>
                                    </div>
                                )}
                            </div>
                            <CardFour />
                            <QandA />
                            <NoCardThree />
                        </div>
                        <Footer />
                    </div>
                </div>

                <div className="flex w-full items-center justify-center">
                    <TrackingContext.Consumer>
                        {({ trackCTA }) => (
                            <button
                                onClick={() => {
                                    trackCTA("teilen");
                                    sharePage();
                                }}
                                className={clsx(
                                    "fixed bottom-0 flex h-[38px] w-[114px] items-center justify-center gap-[10px] bg-blue-500 px-[12px] font-frutigerBlackItalic text-[16px]",
                                    shareViaCopy ? "w-[300px]" : "w-[114px]",
                                )}
                            >
                                {shareViaCopy ? (
                                    <>{"In Zwischenablage kopiert"}</>
                                ) : (
                                    <>
                                        {startLocalized?.shareFeatureText}
                                        <div className="relative h-[24px] max-h-[24px] min-h-[24px] w-[24px] min-w-[24px] max-w-[24px]">
                                            <ExportedImage
                                                src={
                                                    startLocalized?.shareFeatureIcon !== undefined &&
                                                    startLocalized?.shareFeatureIcon !== null
                                                        ? getImageUrlFromGeneralImage(startLocalized.shareFeatureIcon)
                                                        : "No image provided"
                                                }
                                                alt={
                                                    startLocalized?.shareFeatureIcon !== undefined &&
                                                    startLocalized?.shareFeatureIcon !== null
                                                        ? getAltTextFromGeneralImage(startLocalized.shareFeatureIcon)
                                                        : "No image provided"
                                                }
                                                layout="fill"
                                                objectPosition="center"
                                            />
                                        </div>
                                    </>
                                )}
                            </button>
                        )}
                    </TrackingContext.Consumer>
                </div>
            </Tracking>
        </>
    );
};

export default inject("store")(observer(Landing));
