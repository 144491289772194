import React, { ReactNode, useContext, useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { ModalLocalProps } from "@/components/shared/types";
import Button from "@/components/web/buttons/Button";
import Checkbox from "@/components/web/checkboxes/Checkbox";
import InputField from "@/components/web/inputs/InputField";
import store from "stores/store";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import IconOutsideLink from "../icons/IconOutsideLink";
import { TrackingContext } from "@/components/shared/tracking/Tracking";
import Markdown from "react-markdown";

type ActiveCheckbox = string | null;

const ModalLocal: React.FC<ModalLocalProps> = ({ isOpen, onClose, checkedBox }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const radioButtons = [
        {
            key: startLocalized?.cardOneRadioButtonOne ?? "In einer Filiale",
            label: startLocalized?.modalFirstRadioButton ?? "In einer Filiale",
        },
        {
            key: startLocalized?.cardOneRadioButtonTwo ?? "Am Telefon",
            label: startLocalized?.modalSecondRadioButton ?? "Am Telefon",
        },
        {
            key: startLocalized?.cardOneRadioButtonThree ?? "Per Mail",
            label: startLocalized?.modalThirdRadioButton ?? "Per Mail",
        },
    ];

    const [activeCheckbox, setActiveCheckbox] = useState<ActiveCheckbox>(null);
    const [dataPrivacyCheck, setDataPrivacyCheck] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [results, setResults] = useState<any[]>([]);
    const [locationChecked, setLocationChecked] = useState(null);
    const [locationCheckedString, setLocationCheckedString] = useState("");
    const [send, setSend] = useState<boolean>(false);
    const [isCustomer, setCustomer] = useState<boolean>(false);
    const [isNotCustomer, setIsNotCustomer] = useState<boolean>(false);

    const [customerData, setCustomerData] = useState({
        firstName: "",
        lastName: "",
        location: "",
        eMail: "",
    });

    let participatingBuildingsArray: string[] = [];

    const participatingBuildings = () => {
        startLocalized?.participatingSparkassen &&
            startLocalized?.participatingSparkassen.map(
                (building) => building?.title !== undefined && participatingBuildingsArray.push(building?.title),
            );
        return participatingBuildings;
    };

    participatingBuildings();

    useEffect(() => {
        checkedBox !== null && checkedBox !== undefined && setActiveCheckbox(checkedBox);
    }, [checkedBox]);

    useEffect(() => {
        if (inputValue !== "") {
            fetchBuildings(inputValue);
        }
    }, [inputValue]);

    function normalizeString(str: string) {
        return str
            .toLowerCase()
            .replace(/-/g, " ")
            .replace(/\s+/g, "")
            .replace(/[^\w\s]/gi, "");
    }

    const fetchBuildings = async (searchTerm: string) => {
        const baseUrl = `https://institute-finder.sparkasse.de/institutes/search?query=${encodeURIComponent(searchTerm)}`;

        try {
            const response = await fetch(baseUrl, {
                method: "GET",
                headers: {
                    "X-Api-Key": "0731d82b-384f-11ea-bddb-0050568b24f3",
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();

            if (data.institutes && Array.isArray(data.institutes.items)) {
                const filterBuildings = data.institutes.items.filter((building: any) =>
                    participatingBuildingsArray.some(
                        (participatingName) => normalizeString(building.name) === normalizeString(participatingName),
                    ),
                );

                const buildings = filterBuildings.slice(0, 3);
                setResults(buildings);
            } else {
                console.error("Expected data.institutes.items to be an array, but got:", typeof data.institutes.items);
            }

            setShowDropdown(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResults([]);
            setShowDropdown(false);
        }
    };

    const parseTextWithLinks = (text: string) => {
        const linkRegex = /\[url\|(.*?)\|([^|]+?)\|([^|\]]+)\]/g;
        let match: RegExpExecArray | null;
        const output: ReactNode[] = [];
        let lastIndex = 0;

        while ((match = linkRegex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                output.push(text.slice(lastIndex, match.index));
            }

            const [, url, linkText, iconName] = match;
            output.push(
                <a
                    href={url}
                    key={match.index}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#DD0B2F", textDecoration: "none" }}
                >
                    {linkText}
                    {iconName && <IconOutsideLink width="12" height="12" />}
                </a>,
            );
            lastIndex = match.index + match[0].length;
        }

        if (lastIndex < text.length) {
            output.push(text.slice(lastIndex));
        }

        return output;
    };

    const resetData = () => {
        setCustomerData({
            ...customerData,
            ["firstName"]: "",
            ["lastName"]: "",
            ["location"]: "",
            ["eMail"]: "",
        });
        setInputValue("");
        setShowDropdown(false);
        setResults([]);
        setDataPrivacyCheck(false);
        setLocationChecked(null);
        setSend(false);
        setActiveCheckbox(checkedBox ?? "");
    };

    const onCloseModal = () => {
        resetData();
        onClose();
    };

    const handleInputChange = (name: string, value: string) => {
        setCustomerData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (option: ActiveCheckbox) => {
        resetData();
        setActiveCheckbox((prevState) => (prevState === option ? prevState : option));
    };

    const handleLocationChange = (index: any, item: any) => {
        setLocationChecked(index);
        setLocationCheckedString(item);
        console.log(locationChecked);
    };

    const isSendButtonEnabled = () => {
        const { firstName, lastName, eMail } = customerData;

        switch (activeCheckbox) {
            case startLocalized?.cardOneRadioButtonOne:
                return locationChecked !== null;
            case startLocalized?.cardOneRadioButtonTwo:
                return true;
            case startLocalized?.cardOneRadioButtonThree:
                return (isCustomer || isNotCustomer) && firstName && lastName && eMail && dataPrivacyCheck;
            default:
                return false;
        }
    };

    function sendEmail(firstName: string, lastName: string, eMail: string) {
        const url = "https://nl-api.curious.zone/api/mail/";
        const data = {
            firstName: firstName,
            lastName: lastName,
            eMail: eMail,
        };

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => console.log(response.json()))
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleSend = () => {
        if (activeCheckbox === startLocalized?.cardOneRadioButtonOne) {
            const checkedBuilding = startLocalized?.participatingSparkassen.find(
                (item) => item && normalizeString(item.title) === normalizeString(locationCheckedString),
            );
            checkedBuilding && window.open(checkedBuilding.link ?? "/", "_blank");
            onCloseModal();
            trackSubmit("filial-finder", normalizeString(locationCheckedString));
        }
        if (activeCheckbox === startLocalized?.cardOneRadioButtonTwo) {
            window.open(startLocalized?.modalButtonVideoCall.link ?? "/", "_blank");
            onCloseModal();
            trackSubmit("video-call");
        }
        if (activeCheckbox === startLocalized?.cardOneRadioButtonThree) {
            sendEmail(customerData.firstName, customerData.lastName, customerData.eMail);
            setSend(true);
            trackSubmit("e-mail");
        }
    };

    const { trackSubmit } = useContext(TrackingContext);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-[99999999] flex h-[calc(100dvh)] items-center justify-center overflow-y-auto">
            <div
                className="fixed h-[calc(120dvh)] w-full overflow-y-hidden bg-black bg-opacity-50"
                onClick={onCloseModal}
            />
            <div className={cn("absolute flex w-full flex-col", "2xs:w-[448px]", "md:w-[680px]")}>
                <div
                    className={cn(
                        "flex w-full justify-between gap-[16px] border border-b-0 border-blackShade-15 bg-red-primary px-[32px] py-[24px]",
                        "2xs:rounded-tl-[24px] 2xs:rounded-tr-[24px]",
                        "md:rounded-tl-[16px] md:rounded-tr-[16px]",
                        [send && "rounded-[16px] text-center"],
                    )}
                >
                    <div className="font-frutigerBlackItalic text-[28px] uppercase leading-[110%]">
                        {!send && startLocalized?.modalTitle}
                        {send && startLocalized?.modalSuccessfullySubmitted}
                    </div>
                    <button
                        onClick={onCloseModal}
                        className="relative h-[24px] max-h-[24px] min-h-[24px] w-[24px] min-w-[24px] max-w-[24px]"
                    >
                        <ExportedImage
                            src={
                                startLocalized?.modalCloseIcon !== undefined && startLocalized?.modalCloseIcon !== null
                                    ? getImageUrlFromGeneralImage(startLocalized.modalCloseIcon)
                                    : "No image provided"
                            }
                            alt={
                                startLocalized?.modalCloseIcon !== undefined && startLocalized?.modalCloseIcon !== null
                                    ? getAltTextFromGeneralImage(startLocalized.modalCloseIcon)
                                    : "No image provided"
                            }
                            layout="fill"
                            objectPosition="center"
                        />
                    </button>
                </div>
                {!send && (
                    <div
                        className={cn(
                            "flex w-full flex-col gap-[24px] border border-blackShade-15 bg-white px-[32px] py-[24px]",
                            "2xs:rounded-[24px] 2xs:rounded-tl-none 2xs:rounded-tr-none",
                            "md::rounded-[16px] xl:rounded-tl-none xl:rounded-tr-none",
                        )}
                    >
                        <div className="flex flex-col gap-[12px]">
                            <div className="font-frutigerBlackItalic text-base uppercase leading-[110%] text-neutral-900">
                                {startLocalized?.modalCheckboxesTitle}
                            </div>
                            <div className="flex flex-col gap-[16px] rounded-2xl border border-blackShade-15 p-[16px]">
                                {radioButtons.map((radioButton, index) => {
                                    if (index === 2) {
                                        return;
                                    }

                                    return (
                                        <Checkbox
                                            key={radioButton.key}
                                            label={radioButton.label}
                                            checked={activeCheckbox === radioButton.key}
                                            onChange={() => handleCheckboxChange(radioButton.key)}
                                        >
                                            {radioButton.label}
                                        </Checkbox>
                                    );
                                })}
                            </div>
                        </div>
                        {activeCheckbox === startLocalized?.cardOneRadioButtonOne && (
                            <div className="flex flex-col gap-[16px]">
                                <div className="font-frutigerBlackItalic text-base font-extrabold uppercase leading-[110%] text-neutral-900">
                                    {startLocalized?.modalLocationInputTitle}
                                </div>
                                <div className="flex flex-col gap-[12px]">
                                    <InputField
                                        placeholder={
                                            startLocalized?.modalLocationInputPlaceholder !== undefined
                                                ? startLocalized?.modalLocationInputPlaceholder
                                                : ""
                                        }
                                        icon={
                                            <ExportedImage
                                                src={
                                                    startLocalized?.modalLocationInputIcon !== undefined &&
                                                    startLocalized?.modalLocationInputIcon !== null
                                                        ? getImageUrlFromGeneralImage(
                                                              startLocalized.modalLocationInputIcon,
                                                          )
                                                        : "No image provided"
                                                }
                                                alt={
                                                    startLocalized?.modalLocationInputIcon !== undefined &&
                                                    startLocalized?.modalLocationInputIcon !== null
                                                        ? getAltTextFromGeneralImage(
                                                              startLocalized.modalLocationInputIcon,
                                                          )
                                                        : "No image provided"
                                                }
                                                layout="fill"
                                            />
                                        }
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                    />
                                    {showDropdown &&
                                        inputValue.length > 0 &&
                                        (results.length > 0 ? (
                                            <ul className="overflow-auto text-black">
                                                {results.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className="cursor-pointer border-b p-2 last:border-b-0 hover:bg-neutral-50"
                                                    >
                                                        <Checkbox
                                                            key={index}
                                                            label={item.label}
                                                            checked={locationChecked === index}
                                                            onChange={() => handleLocationChange(index, item.name)}
                                                            className="text-black"
                                                        >
                                                            {item.name}
                                                        </Checkbox>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div className="font-frutigerRoman text-[16px] leading-[135%] text-red-primary">
                                                <Markdown>{startLocalized.modalSparkasseNotFoundError}</Markdown>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                        {activeCheckbox === startLocalized?.cardOneRadioButtonTwo && (
                            <div className="font-frutigerRoman text-[16px] leading-[135%] text-neutral-900">
                                Die Beratung erfolgt über unseren Partner, die Sparkasse Vorpommern.
                            </div>
                        )}
                        {activeCheckbox === startLocalized?.cardOneRadioButtonThree && (
                            <>
                                <div className="font-frutigerBlackItalic text-base font-extrabold uppercase leading-[110%] text-neutral-900">
                                    Bist du bereits Kunde einer sparkasse ?
                                </div>
                                <div className="flex flex-col gap-[24px]">
                                    <div className="flex gap-[32px] rounded-[16px] border border-blackShade-15 p-[16px]">
                                        <Checkbox
                                            label="Test"
                                            checked={isCustomer}
                                            onChange={() => {
                                                setCustomer(true), setIsNotCustomer(false);
                                            }}
                                            className="text-black"
                                        >
                                            Ja
                                        </Checkbox>
                                        <Checkbox
                                            label="Test"
                                            checked={isNotCustomer}
                                            onChange={() => {
                                                setIsNotCustomer(true);
                                                setCustomer(false);
                                            }}
                                            className="text-black"
                                        >
                                            Nein
                                        </Checkbox>
                                    </div>
                                    <div className="flex flex-col gap-[12px]">
                                        <InputField
                                            placeholder={
                                                startLocalized?.modalFirstInputPlaceholder !== undefined
                                                    ? startLocalized?.modalFirstInputPlaceholder
                                                    : ""
                                            }
                                            required
                                            value={customerData.firstName}
                                            onChange={(e) => handleInputChange("firstName", e.target.value)}
                                        />
                                        <InputField
                                            placeholder={
                                                startLocalized?.modalSecondInputPlaceholder !== undefined
                                                    ? startLocalized?.modalSecondInputPlaceholder
                                                    : ""
                                            }
                                            required
                                            value={customerData.lastName}
                                            onChange={(e) => handleInputChange("lastName", e.target.value)}
                                        />
                                        <InputField
                                            placeholder={
                                                startLocalized?.modalMailInputPlaceholder !== undefined
                                                    ? startLocalized?.modalMailInputPlaceholder
                                                    : ""
                                            }
                                            required
                                            value={customerData.eMail}
                                            onChange={(e) => handleInputChange("eMail", e.target.value)}
                                        />
                                    </div>
                                    <Checkbox
                                        label="Ich bin damit einverstanden,..."
                                        className="text-base"
                                        checked={dataPrivacyCheck}
                                        onChange={() => setDataPrivacyCheck(!dataPrivacyCheck)}
                                    >
                                        <p>{parseTextWithLinks(startLocalized?.modalDatasecurityCheckbox)}</p>
                                    </Checkbox>
                                </div>
                            </>
                        )}
                        <Button
                            onClick={handleSend}
                            className="max-w-[322px] gap-4 text-[14px]"
                            rightIcon={
                                activeCheckbox !== startLocalized?.cardOneRadioButtonThree ? (
                                    <ExportedImage
                                        src={
                                            startLocalized?.modalButtonConsulting.icon !== undefined &&
                                            startLocalized?.modalButtonConsulting.icon !== null
                                                ? getImageUrlFromGeneralImage(startLocalized.modalButtonConsulting.icon)
                                                : "No image provided"
                                        }
                                        alt={
                                            startLocalized?.modalButtonConsulting.icon !== undefined &&
                                            startLocalized?.modalButtonConsulting.icon !== null
                                                ? getAltTextFromGeneralImage(startLocalized.modalButtonConsulting.icon)
                                                : "No image provided"
                                        }
                                        layout="fill"
                                        objectPosition="center"
                                    />
                                ) : (
                                    <ExportedImage
                                        src={
                                            startLocalized?.modalButtonSendMail.icon !== undefined &&
                                            startLocalized?.modalButtonSendMail.icon !== null
                                                ? getImageUrlFromGeneralImage(startLocalized.modalButtonSendMail.icon)
                                                : "No image provided"
                                        }
                                        alt={
                                            startLocalized?.modalButtonSendMail.icon !== undefined &&
                                            startLocalized?.modalButtonSendMail.icon !== null
                                                ? getAltTextFromGeneralImage(startLocalized.modalButtonSendMail.icon)
                                                : "No image provided"
                                        }
                                        layout="fill"
                                        objectPosition="center"
                                    />
                                )
                            }
                            disabledIcon={
                                activeCheckbox !== startLocalized?.cardOneRadioButtonThree ? (
                                    <ExportedImage
                                        src={
                                            startLocalized?.modalButtonConsulting.disabledIcon !== undefined &&
                                            startLocalized?.modalButtonConsulting.disabledIcon !== null
                                                ? getImageUrlFromGeneralImage(
                                                      startLocalized.modalButtonConsulting.disabledIcon,
                                                  )
                                                : "No image provided"
                                        }
                                        alt={
                                            startLocalized?.modalButtonConsulting.disabledIcon !== undefined &&
                                            startLocalized?.modalButtonConsulting.disabledIcon !== null
                                                ? getAltTextFromGeneralImage(
                                                      startLocalized.modalButtonConsulting.disabledIcon,
                                                  )
                                                : "No image provided"
                                        }
                                        layout="fill"
                                        objectPosition="center"
                                    />
                                ) : (
                                    <ExportedImage
                                        src={
                                            startLocalized?.modalButtonSendMail.disabledIcon !== undefined &&
                                            startLocalized?.modalButtonSendMail.disabledIcon !== null
                                                ? getImageUrlFromGeneralImage(
                                                      startLocalized.modalButtonSendMail.disabledIcon,
                                                  )
                                                : "No image provided"
                                        }
                                        alt={
                                            startLocalized?.modalButtonSendMail.disabledIcon !== undefined &&
                                            startLocalized?.modalButtonSendMail.disabledIcon !== null
                                                ? getAltTextFromGeneralImage(
                                                      startLocalized.modalButtonSendMail.disabledIcon,
                                                  )
                                                : "No image provided"
                                        }
                                        layout="fill"
                                        objectPosition="center"
                                    />
                                )
                            }
                            disabled={!isSendButtonEnabled()}
                        >
                            {activeCheckbox !== startLocalized?.cardOneRadioButtonThree
                                ? startLocalized?.modalButtonConsulting.text
                                : startLocalized?.modalButtonSendMail.text}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalLocal;
