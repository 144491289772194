import { cn, useLG } from "@/lib/utils";
import ExportedImage from "next-image-export-optimizer";
import { useEffect, useState } from "react";
import store from "stores/store";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";

type FooterProps = {
    className?: string;
};

const Footer: React.FC<FooterProps> = ({ className }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getScreenSize = () => {
        if (windowWidth < 1024) {
            return true;
        } else if (windowWidth < 1280) {
            return false;
        }
    };

    const footer = getScreenSize();
    return (
        <>
            <div
                className={cn(
                    "flex h-[316.5px] max-h-[316.5px] w-full justify-center bg-neutral-300 px-[16px] pb-[80px] pt-[48px]",
                    "font-frutigerRoman text-[12px] leading-[135%] text-white",
                    "md:max-h-[238.5px] md:px-[42px]",
                    "lg:max-h-[124.5px] lg:px-[99px] lg:pb-[32px]",
                    className,
                )}
            >
                <div
                    className={cn(
                        "flex flex-col items-center gap-[48px]",
                        "md:w-full md:max-w-[690px] md:items-start",
                        "lg:max-w-[960px] lg:flex-row lg:items-center lg:justify-between",
                        "xl:max-w-[1140px]",
                    )}
                >
                    <div
                        onClick={() => window.open(startLocalized?.footerLogoLink ?? "/", "_blank")}
                        className="relative h-[44.5px] w-[139px] cursor-pointer"
                    >
                        <ExportedImage
                            src={
                                startLocalized?.footerLogo !== undefined && startLocalized?.footerLogo !== null
                                    ? getImageUrlFromGeneralImage(startLocalized?.footerLogo)
                                    : "No image provided"
                            }
                            alt={
                                startLocalized?.footerLogo !== undefined && startLocalized?.footerLogo !== null
                                    ? getAltTextFromGeneralImage(startLocalized?.footerLogo)
                                    : "No image provided"
                            }
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                    {footer && (
                        <div className={cn("flex flex-col gap-[8px]", "md:w-full md:flex-row md:justify-between")}>
                            <div>{startLocalized?.footerClaim}</div>
                            <div className={cn("flex cursor-pointer flex-col items-center gap-2", "md:flex-row")}>
                                <div onClick={() => window.open(startLocalized?.footerImprint.link ?? "/", "_blank")}>
                                    {startLocalized?.footerImprint.title}
                                </div>
                                <div
                                    onClick={() =>
                                        window.open(startLocalized?.footerPrivacyPolicy.link ?? "/", "_blank")
                                    }
                                >
                                    {startLocalized?.footerPrivacyPolicy.title}
                                </div>
                                <div onClick={() => window.open(startLocalized?.footerContact.link ?? "/", "_blank")}>
                                    {startLocalized?.footerContact.title}
                                </div>
                            </div>
                        </div>
                    )}
                    {!footer && (
                        <>
                            <div>{startLocalized?.footerClaim}</div>
                            <div className={cn("flex cursor-pointer flex-col items-center gap-2", "md:flex-row")}>
                                <div onClick={() => window.open(startLocalized?.footerImprint.link ?? "/", "_blank")}>
                                    {startLocalized?.footerImprint.title}
                                </div>
                                <div
                                    onClick={() =>
                                        window.open(startLocalized?.footerPrivacyPolicy.link ?? "/", "_blank")
                                    }
                                >
                                    {startLocalized?.footerPrivacyPolicy.title}
                                </div>
                                <div onClick={() => window.open(startLocalized?.footerContact.link ?? "/", "_blank")}>
                                    {startLocalized?.footerContact.title}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Footer;
