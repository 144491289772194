import React from "react";
import { cn } from "@/lib/utils";
import Markdown from "react-markdown";
import { AnimatePresence, motion } from "framer-motion";

type CardRisksMobileProps = {
    id: number;
    title: string;
    content: string;
    icon: React.ReactNode;
    className?: string;
    activeTab: number;
    setActiveTab: (id: number) => void;
};

const CardRisksMobile: React.FC<CardRisksMobileProps> = ({ id, title, content, icon, activeTab, setActiveTab }) => {
    return (
        <button
            onClick={() => setActiveTab(id)}
            className={cn(
                "h-full w-full border-b border-l border-r border-blackShade-15 bg-white first:rounded-tl-[8px] first:rounded-tr-[8px] first:border-t last:rounded-bl-[8px] last:rounded-br-[8px]",
                [activeTab === id && "bg-neutral-200"],
            )}
        >
            <div className="flex w-full items-center justify-between p-[12px] pl-[16px]">
                <div
                    className={cn("font-frutigerRoman text-[12px] text-neutral-600", [
                        activeTab === id && "font-frutigerBold font-bold text-red-primary",
                    ])}
                >
                    {title}
                </div>
                <div className="relative h-[16px] max-h-[16px] min-h-[16px] w-[16px] min-w-[16px] max-w-[16px]">
                    {icon}
                </div>
            </div>

            {activeTab === id && (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 100 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="markdown-container w-full p-[16px] text-left font-frutigerRoman text-[12px] font-normal leading-[135%] text-neutral-300"
                    >
                        <Markdown>{content}</Markdown>
                    </motion.div>
                </AnimatePresence>
            )}
        </button>
    );
};

export default CardRisksMobile;
