import { cn } from "@/lib/utils";

import { InputFieldProps } from "@/components/shared/types";
import { useEffect, useState } from "react";

const InputField: React.FC<InputFieldProps> = ({
    placeholder,
    icon,
    required = false,
    onChange,
    value,
    isLocation = false,
}) => {
    const [touched, setTouched] = useState(false);

    const isError = required && touched && !value;

    return (
        <div className="relative flex h-[44px] w-full">
            {icon && (
                <div className="bg-red-primary flex h-full w-[48px] items-center justify-center rounded-bl-lg rounded-tl-lg border border-blackShade-15 px-[16px] py-[8px]">
                    <div className="relative flex h-[16px] max-h-[16px] min-h-[16px] w-[16px] min-w-[16px] max-w-[16px] items-center justify-center">
                        {icon}
                    </div>
                </div>
            )}
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange && onChange(e)}
                onBlur={() => setTouched(true)}
                className={cn(
                    "relative w-full border border-blackShade-15 bg-neutral-400 px-[16px] py-[8px]",
                    "font-frutigerRoman text-[16px] leading-[30%] tracking-minus-2 text-neutral-600 placeholder:text-neutral-300",
                    "focus:border-red-primary focus:outline-none focus:ring-0",
                    [icon && "rounded-br-lg rounded-tr-lg border-l-0", !icon && "rounded-lg"],
                    [isError && "border-redShade-15 bg-red-error"],
                )}
            />
            {isError && (
                <div className="text-red-primary absolute right-[8px] top-1/2 font-frutigerRoman text-[10px] leading-[30%] tracking-minus-2">
                    Pflichtfeld
                </div>
            )}
        </div>
    );
};

export default InputField;
