import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import store from "stores/store";
import CardWhite from "../cards/CardWhite";
import CardHeader from "../cards/CardHeader";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import { cn } from "@/lib/utils";

type CardThreeProps = {
    className?: string;
};

const CardThree: React.FC<CardThreeProps> = ({ className }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }
    const { startLocalized } = store;

    return (
        <CardWhite className={className}>
            <div className="flex w-full flex-col gap-[16px]">
                <div className="font-frutigerBlackItalic text-[24px] uppercase leading-[110%] text-red-primary">
                    {startLocalized?.cardThreeTitle}
                </div>
                <div className="font-frutigerRoman text-[16px] leading-[135%] text-neutral-300">
                    {startLocalized?.cardThreeSubtitle}
                </div>
            </div>

            <div
                className={cn(
                    "align-items-center relative grid w-full grid-cols-2 justify-items-center gap-[8px]",
                    "md:flex md:flex-row md:items-center md:justify-center md:gap-[8px]",
                    "lg:align-items-center lg:grid lg:grid-cols-2 lg:justify-items-center lg:gap-[8px]",
                )}
            >
                {startLocalized?.cardThreeImages.map((image, index) => {
                    return (
                        <div
                            key={index}
                            className={cn(
                                "relative grid h-[72px] w-[115px]",
                                "2xs:h-[113px] 2xs:w-[180px]",
                                "md:h-[99px] md:w-[158px]",
                                "lg:h-[85px] lg:w-[135px]",
                                "xl:h-[87px] xl:w-[138px]",
                            )}
                        >
                            <ExportedImage
                                src={
                                    image !== undefined && image !== null
                                        ? getImageUrlFromGeneralImage(image)
                                        : "No image provided"
                                }
                                alt={
                                    image !== undefined && image !== null
                                        ? getAltTextFromGeneralImage(image)
                                        : "No image provided"
                                }
                                layout="fill"
                                objectPosition="cover"
                            />
                        </div>
                    );
                })}
            </div>
        </CardWhite>
    );
};

export default inject("store")(observer(CardThree));
