import React from "react";

import { cn } from "@/lib/utils";

type CardHeaderProps = {
    title?: string;
    content?: string;
    icon?: JSX.Element;
    contentColor?: string;
    className?: string;
};

const CardHeader: React.FC<CardHeaderProps> = ({ title, content, contentColor, icon, className }) => {
    return (
        <div className={cn("CardHeader | flex flex-col gap-[16px]", className)}>
            <div className="relative h-[32px] max-h-[32px] min-h-[32px] w-[32px] min-w-[32px] max-w-[32px]">{icon}</div>
            <div className="hyphens-auto break-words font-frutigerBlackItalic text-[24px] uppercase leading-[110%] text-red-primary">
                {title}
            </div>
            {content && (
                <div className={cn("font-frutigerRoman text-[16px] leading-[135%] text-neutral-300", contentColor)}>
                    {content}
                </div>
            )}
        </div>
    );
};

export default CardHeader;
