import store from "stores/store";
import Button from "../buttons/Button";
import CardWhite from "../cards/CardWhite";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import { cn } from "@/lib/utils";
import { useContext } from "react";
import { TrackingContext } from "@/components/shared/tracking/Tracking";

const NoCardTwo = () => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }
    const { startLocalized } = store;

    const { trackCTA } = useContext(TrackingContext);

    return (
        <div className={cn("flex flex-col items-center gap-[16px]", "md:items-start")}>
            <div className="flex flex-col gap-[16px]">
                <div className="font-frutigerBlackItalic text-[24px] uppercase leading-[110%] text-red-primary">
                    {startLocalized?.noCardTwoTitle}
                </div>
                <div className="font-frutigerRoman text-[16px] leading-[135%] text-neutral-300">
                    {startLocalized?.noCardTwoContent}
                </div>
            </div>
            <Button
                onClick={() => {
                    trackCTA("jetzt-online-abschliessen");
                    window.open(startLocalized?.noCardTwoButton.link ?? "/", "_blank");
                }}
                className="md:w-[374px] lg:w-full"
                rightIcon={
                    <ExportedImage
                        src={
                            startLocalized?.noCardTwoButton.icon !== undefined &&
                            startLocalized?.noCardTwoButton.icon !== null
                                ? getImageUrlFromGeneralImage(startLocalized.noCardTwoButton.icon)
                                : "No image provided"
                        }
                        alt={
                            startLocalized?.noCardTwoButton.icon !== undefined &&
                            startLocalized?.noCardTwoButton.icon !== null
                                ? getAltTextFromGeneralImage(startLocalized.noCardTwoButton.icon)
                                : "No image provided"
                        }
                        layout="fill"
                        objectPosition="center"
                    />
                }
            >
                {startLocalized?.noCardTwoButton.title}
            </Button>
            <div className="font-frutigerRoman text-[13px] leading-[135%] text-neutral-300">
                {startLocalized?.noCardTwoHotlineHint}
                <a href={`tel:${startLocalized?.noCardTwoHotlineNumber}`} className="text-red-primary">
                    {startLocalized?.noCardTwoHotlineNumber}
                </a>
            </div>
        </div>
    );
};

export default NoCardTwo;
