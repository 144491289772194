import { inject, observer } from "mobx-react";
import store from "stores/store";
import CardWhite from "../cards/CardWhite";
import Checkbox from "../checkboxes/Checkbox";
import Button from "../buttons/Button";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import { cn } from "@/lib/utils";
import { useContext, useState } from "react";
import ModalLocal from "../modals/ModalLocal";
import { TrackingContext } from "@/components/shared/tracking/Tracking";

type CardOneProps = {
    className?: string;
};

type ActiveCheckbox = string | null;

const CardOne: React.FC<CardOneProps> = ({ className }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const radioButtons = [
        {
            key: startLocalized?.cardOneRadioButtonOne ?? "In einer Sparkasse",
            label: startLocalized?.cardOneRadioButtonOne ?? "In einer Sparkasse",
        },
        {
            key: startLocalized?.cardOneRadioButtonTwo ?? "via Video-Call",
            label: startLocalized?.cardOneRadioButtonTwo ?? "via Video-Call",
        },
        {
            key: startLocalized?.cardOneRadioButtonThree ?? "per E-Mail",
            label: startLocalized?.cardOneRadioButtonThree ?? "per E-Mail",
        },
    ];

    const [activeCheckbox, setActiveCheckbox] = useState<ActiveCheckbox>(radioButtons[0].label);

    const handleCheckboxChange = (option: ActiveCheckbox) => {
        setActiveCheckbox((prevState) => (prevState === option ? prevState : option));
    };

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const handleSubmit = () => {
        if (activeCheckbox === radioButtons[0].label || activeCheckbox === radioButtons[2].label) {
            setModalOpen(true);
        } else {
            window.open(startLocalized?.modalButtonVideoCall.link ?? "/", "_blank");
        }
    };

    const { trackCTA } = useContext(TrackingContext);

    return (
        <>
            <CardWhite
                className={cn(
                    "gap-[16px] px-[20px] pb-[24px] pt-[20px]",
                    "lg:items-end lg:gap-[24px] lg:p-[32px]",
                    className,
                )}
            >
                <div
                    className={cn(
                        "font-frutigerBlack text-[16px] uppercase italic leading-[110%] text-red-primary",
                        "lg:text-[20px]",
                    )}
                >
                    {startLocalized?.cardOneTitle}
                </div>
                <div className="flex h-full w-full flex-col justify-between gap-[16px] lg:gap-[24px]">
                    <div
                        className={cn(
                            "flex w-full flex-col gap-[8px]",
                            "2xs:flex-row",
                            "md:flex-col",
                            "lg:flex-row",
                            "lg:gap-[16px]",
                            // "lg:justify-between lg:gap-[0px]"
                        )}
                    >
                        {radioButtons.map((radioButton, index) => {
                            if (index === 2) {
                                return;
                            }

                            return (
                                <Checkbox
                                    textColor="text-red-primary font-frutigerBold"
                                    key={radioButton.key}
                                    label={radioButton.label}
                                    checked={activeCheckbox === radioButton.key}
                                    onChange={() => handleCheckboxChange(radioButton.key)}
                                >
                                    {radioButton.label}
                                </Checkbox>
                            );
                        })}
                    </div>
                    <div className={cn("flex flex-col gap-[12px]", "xl:flex-row xl:justify-end")}>
                        {activeCheckbox === radioButtons[1].label && (
                            <div className="font-frutigerRoman text-[12px] leading-[135%] text-neutral-300">
                                *Die Beratung erfolgt über unseren Partner, die Sparkasse Vorpommern.
                            </div>
                        )}
                        <Button
                            onClick={() => {
                                handleSubmit(), trackCTA("jetzt-beraten-lassen-1");
                            }}
                            className={cn(
                                "py-[6px] pl-[16px] pr-[6px] text-[14px]",
                                "lg:py-[12px] lg:text-[16px]",
                                "xl:w-[275px] xl:min-w-[275px]",
                            )}
                            rightIcon={
                                <ExportedImage
                                    src={
                                        startLocalized?.cardOneButton.icon !== undefined &&
                                        startLocalized?.cardOneButton.icon !== null
                                            ? getImageUrlFromGeneralImage(startLocalized.cardOneButton.icon)
                                            : "No image provided"
                                    }
                                    alt={
                                        startLocalized?.cardOneButton.icon !== undefined &&
                                        startLocalized?.cardOneButton.icon !== null
                                            ? getAltTextFromGeneralImage(startLocalized.cardOneButton.icon)
                                            : "No image provided"
                                    }
                                    layout="fill"
                                    objectPosition="center"
                                />
                            }
                        >
                            {startLocalized?.cardOneButton.text}
                        </Button>
                    </div>
                </div>
            </CardWhite>
            <ModalLocal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)} checkedBox={activeCheckbox} />
        </>
    );
};

export default inject("store")(observer(CardOne));
