import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import store from "stores/store";
import CardWhite from "../cards/CardWhite";
import CardHeader from "../cards/CardHeader";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import CardBenefit from "../cards/CardBenefit";
import { cn } from "@/lib/utils";

type CardTwoProps = {
    className?: string;
};

const CardTwo: React.FC<CardTwoProps> = ({ className }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }
    const { startLocalized } = store;
    return (
        <CardWhite className={cn("lg:p-[32px]", className)}>
            <CardHeader
                title={startLocalized?.cardTwoTitle.text}
                content={startLocalized?.cardTwoContent}
                icon={
                    <ExportedImage
                        src={
                            startLocalized?.cardTwoTitle.icon !== undefined &&
                            startLocalized?.cardTwoTitle.icon !== null
                                ? getImageUrlFromGeneralImage(startLocalized.cardTwoTitle.icon)
                                : "No image provided"
                        }
                        alt={
                            startLocalized?.cardTwoTitle.icon !== undefined &&
                            startLocalized?.cardTwoTitle.icon !== null
                                ? getAltTextFromGeneralImage(startLocalized.cardTwoTitle.icon)
                                : "No image provided"
                        }
                        layout="fill"
                        objectPosition="center"
                    />
                }
            />
            {startLocalized?.cardTwoBenefits.map((benefit, index) => (
                <CardBenefit
                    key={index}
                    title={benefit?.title}
                    subtitle={benefit?.subtitle}
                    icon={
                        <ExportedImage
                            src={
                                benefit?.icon !== undefined && benefit?.icon !== null
                                    ? getImageUrlFromGeneralImage(benefit.icon)
                                    : "No image provided"
                            }
                            alt={
                                benefit?.icon !== undefined && benefit?.icon !== null
                                    ? getAltTextFromGeneralImage(benefit.icon)
                                    : "No image provided"
                            }
                            layout="fill"
                        />
                    }
                />
            ))}
        </CardWhite>
    );
};

export default inject("store")(observer(CardTwo));
