import Footer from "@/components/web/footer/Footer";
import Hero404 from "@/components/web/heros/Hero404";
import React from "react";

const Custom404: React.FunctionComponent = () => {
    return (
        <div className="flex h-screen w-screen flex-col overflow-hidden">
            <div className="h-full w-full">
                <Hero404 />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default Custom404;
