import * as React from "react";
import { cn } from "@/lib/utils";
import { DropdownProps } from "@/components/shared/types";
import IconChevronWithBg from "../icons/IconChevronWithBg";
import { AnimatePresence, motion } from "framer-motion";
import Markdown from "react-markdown";

const Dropdown: React.FC<DropdownProps> = ({ content, open, className, title, link, linkIcon, onLink }) => {
    return (
        <div
            className={cn(
                "flex w-full flex-col items-center gap-[16px] rounded-[24px] border border-blackShade-15 bg-white py-[16px] pl-[24px] pr-[16px] shadow-dropdown-outer",
                [open && "pb-[24px]"],
                className,
            )}
        >
            <div className="flex w-full items-center justify-between gap-[16px] text-left">
                <div className="font-frutigerBlackItalic text-[16px] uppercase leading-[110%] text-neutral-900">
                    {title}
                </div>
                <AnimatePresence>
                    <motion.div
                        initial={{ rotate: 0 }}
                        animate={{ rotate: open ? 90 : 0 }}
                        transition={{ duration: 0.3 }}
                        exit={{ rotate: 0 }}
                    >
                        <IconChevronWithBg />
                    </motion.div>
                </AnimatePresence>
            </div>
            <AnimatePresence>
                {open && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        transition={{ duration: 0.3 }}
                        exit={{ height: 0 }}
                        className="flex flex-col gap-[16px] text-left"
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 100 }}
                            transition={{ duration: 0.2 }}
                            exit={{ opacity: 0 }}
                            className={cn(
                                "font-frutigerRoman text-[12px] leading-[135%] text-neutral-300",
                                "lg:text-[16px]",
                            )}
                        >
                            <Markdown>{content ?? ""}</Markdown>
                        </motion.div>
                        {link && (
                            <div className="flex items-center gap-[10px]">
                                <div
                                    onClick={onLink}
                                    className="font-frutigerRoman text-[16px] text-red-primary underline"
                                >
                                    {link}
                                </div>

                                {linkIcon && (
                                    <div className="relative h-[16px] max-h-[16px] min-h-[16px] w-[16px] min-w-[16px] max-w-[16px]">
                                        {linkIcon}
                                    </div>
                                )}
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Dropdown;
