import store from "stores/store";
import Dropdown from "../dropdowns/Dropdown";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import { useEffect, useState } from "react";
import CardHeader from "../cards/CardHeader";

//DROPDOWNS ÜBERARBEITEN (SCHATTEN)

const QandA = () => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }
    const { startLocalized } = store;

    useEffect(() => {
        if (startLocalized?.dropdowns) {
            setOpenDropdowns(Array(startLocalized.dropdowns.length).fill(false));
        }
    }, [startLocalized?.dropdowns]);

    const [openDropdowns, setOpenDropdowns] = useState<any[]>([]);

    const toggleDropdown = (index: number) => {
        const updatedOpenDropdowns = openDropdowns.map((isOpen, idx) => (index === idx ? !isOpen : isOpen));
        setOpenDropdowns(updatedOpenDropdowns);
    };

    return (
        <div className="flex h-full w-full flex-col gap-[24px]">
            <CardHeader
                title={startLocalized?.dropdownsTitle.text}
                icon={
                    <ExportedImage
                        src={
                            startLocalized?.dropdownsTitle.icon !== undefined &&
                            startLocalized?.dropdownsTitle.icon !== null
                                ? getImageUrlFromGeneralImage(startLocalized.dropdownsTitle.icon)
                                : "No image provided"
                        }
                        alt={
                            startLocalized?.dropdownsTitle.icon !== undefined &&
                            startLocalized?.dropdownsTitle.icon !== null
                                ? getAltTextFromGeneralImage(startLocalized.dropdownsTitle.icon)
                                : "No image provided"
                        }
                        layout="fill"
                        objectPosition="center"
                    />
                }
            />
            <div className="flex flex-col gap-[8px]">
                {startLocalized?.dropdowns.map(
                    (dropdown, index) =>
                        dropdown !== undefined &&
                        dropdown !== null && (
                            <button key={index} onClick={() => toggleDropdown(index)} className="w-full">
                                <Dropdown
                                    title={dropdown?.title}
                                    open={openDropdowns[index]}
                                    content={dropdown.content}
                                    link={dropdown.link && dropdown.link}
                                    linkIcon={
                                        dropdown.linkIcon?.image !== undefined &&
                                        dropdown.linkIcon?.image !== null && (
                                            <ExportedImage
                                                src={getImageUrlFromGeneralImage(dropdown.linkIcon)}
                                                alt={getAltTextFromGeneralImage(dropdown.linkIcon)}
                                                layout="fill"
                                                objectPosition="center"
                                            />
                                        )
                                    }
                                />
                            </button>
                        ),
                )}
            </div>
        </div>
    );
};

export default QandA;
