/* eslint-disable react-hooks/exhaustive-deps */
import { ChatbotUiState, WebUiState } from "@/components/enums/UiState";
import { useEffect } from "react";
import Landing from "../components/pages/web/Landing";
import { Store } from "stores/store";
import {
    AgeEntity,
    CancelCallEntity,
    ChatbotEntity,
    ComingSoonEntity,
    EndOfCallOldEntity,
    EndOfCallRegularEntity,
    GlobalEntity,
    HintEntity,
    I18NEntity,
    LandingEntity,
    LoadingEntity,
    MaskEntity,
    MetaEntity,
    PageNotFoundEntity,
    PostProcessingEntity,
    StartEntity,
    TutorialEntity,
} from "types/strapi";
import { inject, observer } from "mobx-react";
import axios from "axios";
import { fetchFromStrapi } from "utils/graphqlClient";
import {
    ageQuery,
    allI18nsQuery,
    cancelCallQuery,
    chatbotQuery,
    comingSoonQuery,
    endOfCallOldQuery,
    endOfCallRegularQuery,
    globalQuery,
    hintQuery,
    landingQuery,
    loadingQuery,
    maskQuery,
    metaQuery,
    pageNotFoundQuery,
    postProcessings,
    startQuery,
    tutorialQuery,
} from "utils/graphql/queries";
import Custom404 from "../components/pages/web/404";
import Head from "next/head";
import InAppSpy from "inapp-spy";
import useUserDevice from "@/components/shared/webcam/hooks/useUserDevice";

function Index(props: {
    store?: Store;
    i18nData: I18NEntity[];
    locales: string[];
    localeNames: string[];
    metaData: MetaEntity;
    postProcessings: PostProcessingEntity[];
    landingData: LandingEntity;
    ageData: AgeEntity;
    cancelCallData: CancelCallEntity;
    endOfCallOldData: EndOfCallOldEntity;
    endOfCallRegularData: EndOfCallRegularEntity;
    hintData: HintEntity;
    loadingData: LoadingEntity;
    maskData: MaskEntity;
    tutorialData: TutorialEntity;
    globalData: GlobalEntity;
    startData: StartEntity;
    comingSoonData: ComingSoonEntity;
    pageNotFoundData: PageNotFoundEntity;
    chatbotData: ChatbotEntity;
}) {
    if (!props.store) {
        throw Error("Component was not setup correctly");
    }

    const {
        renderWebContent,
        landingLocalized,
        metaLocalized,
        updateMetaLocalized,
        updateLandingLocalized,
        ageLocalized,
        updateAgeLocalized,
        cancelCallLocalized,
        updateCancelCallLocalized,
        endOfCallOldLocalized,
        updateEndOfCallOldLocalized,
        endOfCallRegularLocalized,
        updateEndOfCallRegularLocalized,
        hintLocalized,
        updateHintLocalized,
        loadingLocalized,
        updateLoadingLocalized,
        maskLocalized,
        updateMaskLocalized,
        tutorialLocalized,
        updateTutorialLocalized,
        globalLocalized,
        updateGlobalLocalized,
        startLocalized,
        updateStartLocalized,
        comingSoonLocalized,
        updateComingSoonLocalized,
        pageNotFoundLocalized,
        updatePageNotFoundLocalized,
        locale,
        locales,
        updateLocale,
        updateLocales,
        updateLocaleNames,
        updateRenderChatbotContent,
        chatbotLocalized,
        updateChatbotLocalized,
    } = props.store;

    useEffect(() => {
        updateLocales(props.locales);
        updateLocaleNames(props.localeNames);
    }, []);

    useEffect(() => {
        const browserLanguage = navigator.language;

        let languageInitialized = false;
        for (let localeIndex = 0; localeIndex < locales.length; localeIndex++) {
            const locale = locales[localeIndex];

            if (browserLanguage.includes(locale)) {
                languageInitialized = true;
                updateLocale(locale, true);
            }
        }

        if (!languageInitialized) {
            updateLocale(locales[0], true);
        }
    }, [locales]);

    useEffect(() => {
        if (metaLocalized?.locale !== locale && locale !== undefined) {
            if (props.metaData.attributes?.locale === locale) {
                updateMetaLocalized(props.metaData.attributes);
            } else {
                if (props.metaData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.metaData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.metaData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateMetaLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (landingLocalized?.locale !== locale && locale !== undefined) {
            if (props.landingData.attributes?.locale === locale) {
                updateLandingLocalized(props.landingData.attributes);
            } else {
                if (props.landingData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.landingData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.landingData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateLandingLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (ageLocalized?.locale !== locale && locale !== undefined) {
            if (props.ageData.attributes?.locale === locale) {
                updateAgeLocalized(props.ageData.attributes);
            } else {
                if (props.ageData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.ageData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.ageData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateAgeLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (cancelCallLocalized?.locale !== locale && locale !== undefined) {
            if (props.cancelCallData.attributes?.locale === locale) {
                updateCancelCallLocalized(props.cancelCallData.attributes);
            } else {
                if (props.cancelCallData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.cancelCallData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.cancelCallData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateCancelCallLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (endOfCallOldLocalized?.locale !== locale && locale !== undefined) {
            if (props.endOfCallOldData.attributes?.locale === locale) {
                updateEndOfCallOldLocalized(props.endOfCallOldData.attributes);
            } else {
                if (props.endOfCallOldData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.endOfCallOldData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.endOfCallOldData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateEndOfCallOldLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (endOfCallRegularLocalized?.locale !== locale && locale !== undefined) {
            if (props.endOfCallRegularData.attributes?.locale === locale) {
                updateEndOfCallRegularLocalized(props.endOfCallRegularData.attributes);
            } else {
                if (props.endOfCallRegularData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.endOfCallRegularData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization =
                            props.endOfCallRegularData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateEndOfCallRegularLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (hintLocalized?.locale !== locale && locale !== undefined) {
            if (props.hintData.attributes?.locale === locale) {
                updateHintLocalized(props.hintData.attributes);
            } else {
                if (props.hintData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.hintData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.hintData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateHintLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (loadingLocalized?.locale !== locale && locale !== undefined) {
            if (props.loadingData.attributes?.locale === locale) {
                updateLoadingLocalized(props.loadingData.attributes);
            } else {
                if (props.loadingData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.loadingData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.loadingData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateLoadingLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (globalLocalized?.locale !== locale && locale !== undefined) {
            if (props.globalData.attributes?.locale === locale) {
                updateGlobalLocalized(props.globalData.attributes);
            } else {
                if (props.globalData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.globalData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.globalData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateGlobalLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (maskLocalized?.locale !== locale && locale !== undefined) {
            if (props.maskData.attributes?.locale === locale) {
                updateMaskLocalized(props.maskData.attributes);
            } else {
                if (props.maskData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.maskData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.maskData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateMaskLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (tutorialLocalized?.locale !== locale && locale !== undefined) {
            if (props.tutorialData.attributes?.locale === locale) {
                updateTutorialLocalized(props.tutorialData.attributes);
            } else {
                if (props.tutorialData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.tutorialData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.tutorialData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateTutorialLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (startLocalized?.locale !== locale && locale !== undefined) {
            if (props.startData.attributes?.locale === locale) {
                updateStartLocalized(props.startData.attributes);
            } else {
                if (props.startData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.startData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.startData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateStartLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (comingSoonLocalized?.locale !== locale && locale !== undefined) {
            if (props.comingSoonData.attributes?.locale === locale) {
                updateComingSoonLocalized(props.comingSoonData.attributes);
            } else {
                if (props.comingSoonData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.comingSoonData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.comingSoonData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateComingSoonLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (pageNotFoundLocalized?.locale !== locale && locale !== undefined) {
            if (props.pageNotFoundData.attributes?.locale === locale) {
                updatePageNotFoundLocalized(props.pageNotFoundData.attributes);
            } else {
                if (props.pageNotFoundData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.pageNotFoundData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.pageNotFoundData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updatePageNotFoundLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (chatbotLocalized?.locale !== locale && locale !== undefined) {
            if (props.chatbotData.attributes?.locale === locale) {
                updateChatbotLocalized(props.chatbotData.attributes);
            } else {
                if (props.chatbotData.attributes?.localizations) {
                    for (
                        let localizationsIndex = 0;
                        localizationsIndex < props.chatbotData.attributes.localizations.data.length;
                        localizationsIndex++
                    ) {
                        const localization = props.chatbotData.attributes.localizations.data[localizationsIndex];

                        if (localization.attributes?.locale === locale) {
                            updateChatbotLocalized(localization.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    const { isInApp, appKey, appName } = InAppSpy()

    const { device } = useUserDevice()

    return (
        <>
            <Head>
                <title>{metaLocalized?.pageTitle}</title>
            </Head>
            <div className="h-[calc(100dvh)] w-screen">
                {renderWebContent === WebUiState.LANDING && (
                    <Landing
                        onCall={() => {
                            // updateRenderChatbotContent(ChatbotUiState.CHATBOT);
                            // window.location.href = "/chatbot";
                            if (isInApp && device?.isAndroid) {
                                window.location.replace(`intent:${window.location.origin}/chatbot#Intent;end`)
                            } else {
                                window.open("/chatbot", '_blank', 'noopener,noreferrer')
                            }

                        }}
                        onTutorial={() => {
                            // updateRenderChatbotContent(ChatbotUiState.TUTORIAL);
                            // window.location.href = "/chatbot";
                            if (isInApp && device?.isAndroid) {
                                window.location.replace(`intent:${window.location.origin}/chatbot#Intent;end`)
                            } else {
                                window.open("/chatbot", '_blank', 'noopener,noreferrer')
                            }
                        }}
                    />
                )}
                {renderWebContent === WebUiState.NONE && <Custom404 />}
                {/* {renderWebContent === WebUiState.AGE && (
                <Age
                    onAge={() => updateRenderWebContent(WebUiState.HINT)}
                    onCancel={() => updateRenderWebContent(WebUiState.LANDING)}
                />
            )}
            {renderWebContent === WebUiState.HINT && (
                <Hint
                    onCall={() => updateRenderWebContent(WebUiState.CHAT)}
                    onCancel={() => updateRenderWebContent(WebUiState.LANDING)}
                />
            )}
             {renderWebContent === WebUiState.CHAT && <Chatbot />}
            {renderWebContent === WebUiState.TUTORIAL && (
                <Tutorial
                    onCall={() => updateRenderWebContent(WebUiState.HINT)}
                    onCancel={() => updateRenderWebContent(WebUiState.LANDING)}
                />
            )}
             */}
            </div>
        </>
    );
}

export async function getStaticProps() {
    const localesData = await axios.get<
        {
            id: number;
            name: string;
            code: string;
            createdAt: string;
            updatedAt: string;
            isDefault: boolean;
        }[]
    >(process.env.NEXT_PUBLIC_STRAPI_URL + "/api/i18n/locales");

    const locales = [];
    const localeNames = [];

    for (let localeDataIndex = 0; localeDataIndex < localesData.data.length; localeDataIndex++) {
        const localeData = localesData.data[localeDataIndex];

        locales.push(localeData.code);
        localeNames.push(localeData.name.split(" ")[0]);
    }

    const i18nData = await fetchFromStrapi(allI18nsQuery);
    const metaData = await fetchFromStrapi(metaQuery);
    const postProcessingData = await fetchFromStrapi(postProcessings);
    const landingData = await fetchFromStrapi(landingQuery);
    const ageData = await fetchFromStrapi(ageQuery);
    const cancelCallData = await fetchFromStrapi(cancelCallQuery);
    const endOfCallOldData = await fetchFromStrapi(endOfCallOldQuery);
    const endOfCallRegularData = await fetchFromStrapi(endOfCallRegularQuery);
    const hintData = await fetchFromStrapi(hintQuery);
    const loadingData = await fetchFromStrapi(loadingQuery);
    const maskData = await fetchFromStrapi(maskQuery);
    const tutorialData = await fetchFromStrapi(tutorialQuery);
    const globalData = await fetchFromStrapi(globalQuery);
    const startData = await fetchFromStrapi(startQuery);
    const comingSoonData = await fetchFromStrapi(comingSoonQuery);
    const pageNotFoundData = await fetchFromStrapi(pageNotFoundQuery);
    const chatbotData = await fetchFromStrapi(chatbotQuery);

    return {
        props: {
            i18nData: i18nData.i18Ns.data,
            locales,
            localeNames,
            metaData: metaData.meta.data,
            postProcessings: postProcessingData.postProcessings.data,
            landingData: landingData.landing.data,
            ageData: ageData.age.data,
            cancelCallData: cancelCallData.cancelCall.data,
            endOfCallOldData: endOfCallOldData.endOfCallOld.data,
            endOfCallRegularData: endOfCallRegularData.endOfCallRegular.data,
            hintData: hintData.hint.data,
            loadingData: loadingData.loading.data,
            maskData: maskData.mask.data,
            tutorialData: tutorialData.tutorial.data,
            globalData: globalData.global.data,
            startData: startData.start.data,
            comingSoonData: comingSoonData.comingSoon.data,
            pageNotFoundData: pageNotFoundData.pageNotFound.data,
            chatbotData: chatbotData.chatbot.data,
        },
    };
}

export default inject("store")(observer(Index));
