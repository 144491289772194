import ExportedImage from "next-image-export-optimizer";
import CardHeader from "../cards/CardHeader";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import store from "stores/store";
import { inject, observer } from "mobx-react";

const NoCardOne = () => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }
    const { startLocalized } = store;
    return (
        <div className="flex flex-col gap-[16px]">
            <CardHeader
                title={startLocalized?.noCardOneTitle.text}
                content={startLocalized?.noCardOneContent}
                contentColor="text-neutral-600"
                icon={
                    <ExportedImage
                        src={
                            startLocalized?.noCardOneTitle.icon !== undefined &&
                            startLocalized?.noCardOneTitle.icon !== null
                                ? getImageUrlFromGeneralImage(startLocalized.noCardOneTitle.icon)
                                : "No image provided"
                        }
                        alt={
                            startLocalized?.noCardOneTitle.icon !== undefined &&
                            startLocalized?.noCardOneTitle.icon !== null
                                ? getAltTextFromGeneralImage(startLocalized.noCardOneTitle.icon)
                                : "No image provided"
                        }
                        layout="fill"
                        objectPosition="center"
                    />
                }
            />
            <div className="font-frutigerRoman text-[12px] leading-[135%] text-neutral-300">
                {startLocalized?.noCardOneAsterisk}
            </div>
        </div>
    );
};

export default inject("store")(observer(NoCardOne));
