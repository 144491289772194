import { cn, use2XS, use4XL, use4XS, useLG, useMD, useXL } from "@/lib/utils";
import { inject, observer } from "mobx-react";
import ExportedImage from "next-image-export-optimizer";
import { useEffect, useState } from "react";
import store from "stores/store";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import Button from "../buttons/Button";

type HeroNewProps = {};

const Hero404: React.FC<HeroNewProps> = ({}) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const LG = useLG();

    const { startLocalized, pageNotFoundLocalized } = store;

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getImageSize = () => {
        if (windowWidth < 768) {
            return pageNotFoundLocalized?.heroMobile;
        } else if (windowWidth >= 768) {
            return pageNotFoundLocalized?.heroDesktop;
        }
    };

    const hero = getImageSize();

    return (
        <div className={cn("relative flex h-full w-full items-center justify-center")}>
            {hero !== undefined && hero !== null && (
                <div className="relative flex h-full w-full flex-col">
                    {pageNotFoundLocalized?.heroOverlay !== undefined &&
                        pageNotFoundLocalized?.heroOverlay !== null && (
                            <div className="relative h-full w-full">
                                <ExportedImage
                                    src={getImageUrlFromGeneralImage(hero)}
                                    alt={getAltTextFromGeneralImage(hero)}
                                    layout="fill"
                                    objectFit="cover"
                                />
                                {!LG && (
                                    <ExportedImage
                                        src={getImageUrlFromGeneralImage(pageNotFoundLocalized?.heroOverlay)}
                                        alt={getAltTextFromGeneralImage(pageNotFoundLocalized?.heroOverlay)}
                                        layout="fill"
                                        objectFit="cover"
                                    />
                                )}
                            </div>
                        )}
                </div>
            )}
            <div
                className={cn(
                    "absolute h-full w-full max-w-[320px]",
                    "2xs:max-w-[480px]",
                    "md:max-w-[768px]",
                    "lg:max-w-[912px] lg:justify-start",
                    "xl:max-w-[1108px]",
                )}
            >
                {startLocalized?.claim !== undefined && startLocalized.claim !== null && (
                    <div
                        className={cn(
                            "absolute left-0 top-[67px] h-[106px] w-[160px]",
                            "2xs:left-[16px] 2xs:top-[47px] 2xs:h-[146px] 2xs:w-[219px]",
                            "md:h-[238px] md:w-[358px]",
                            "lg:left-auto lg:right-[0px] lg:top-[24px] lg:h-[165px] lg:w-[260px]",
                        )}
                    >
                        <ExportedImage
                            src={getImageUrlFromGeneralImage(startLocalized?.claim)}
                            alt={getAltTextFromGeneralImage(startLocalized?.claim)}
                            layout="fill"
                        />
                    </div>
                )}
                <div
                    className={cn(
                        "absolute bottom-[48.5px] flex w-full flex-col gap-[16px] px-[16px]",
                        "lg:bottom-0 lg:h-full lg:w-[284px] lg:justify-center lg:px-0",
                    )}
                >
                    <div className="font-frutigerBlackItalic text-[96px] leading-[70%] text-white text-shadow-drop-black-25">
                        {pageNotFoundLocalized?.title}
                    </div>
                    <div className="font-frutigerRoman text-[12px] leading-[125%] tracking-minus-2 text-shadow-drop-black-25 lg:text-[18px]">
                        {pageNotFoundLocalized?.subtitle}
                    </div>
                    <Button
                    className={cn(
                        "py-[6px] pl-[16px] pr-[6px] text-[14px]",
                        "lg:h-[48px] lg:py-[12px] lg:pl-[24px] lg:pr-[12px] lg:text-[16px]",
                    )}
                        rightIcon={
                            <ExportedImage
                                src={
                                    pageNotFoundLocalized?.button.icon !== undefined &&
                                    pageNotFoundLocalized?.button.icon !== null
                                        ? getImageUrlFromGeneralImage(pageNotFoundLocalized.button.icon)
                                        : "No image provided"
                                }
                                alt={
                                    pageNotFoundLocalized?.button.icon !== undefined &&
                                    pageNotFoundLocalized?.button.icon !== null
                                        ? getAltTextFromGeneralImage(pageNotFoundLocalized.button.icon)
                                        : "No image provided"
                                }
                                layout="fill"
                                objectPosition="center"
                            />
                        }
                    >
                        {pageNotFoundLocalized?.button.text}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default inject("store")(observer(Hero404));
