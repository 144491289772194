import * as React from "react";

import { cn } from "@/lib/utils";

import { ButtonProps } from "@/components/shared/types";

const IconContainer: React.FC<{ icon: JSX.Element }> = ({ icon }) => (
    <div className="relative flex h-[24px] max-h-[24px] min-h-[24px] w-[24px] min-w-[24px] max-w-[24px] items-center justify-center">
        {icon}
    </div>
);

const ButtonSecondary: React.FC<ButtonProps> = ({ children, className, leftIcon, rightIcon, ...rest }) => {
    return (
        <button
            type="button"
            className={cn(
                "text-left font-frutigerBlackItalic text-[16px] uppercase leading-[102%]",
                "w-full rounded-full border border-whiteShade-65 bg-button-secondary-default bg-cover bg-center px-[24px] py-[13px] shadow-button-secondary-default backdrop-blur-[72px]",
                "2xs:py-[17px]",
                "lg:py-[14px] lg:text-[22px] lg:leading-[135%]",
                "active:bg-button-secondary-active",
                [leftIcon && "pl-[18px] pr-[24px]", rightIcon && "pl-[24px] pr-[18px]"],
                className,
            )}
            {...rest}
        >
            <div className="flex w-full items-center justify-between gap-4 text-white">
                {leftIcon && <IconContainer icon={leftIcon} />}
                {children}
                {rightIcon && <IconContainer icon={rightIcon} />}
            </div>
        </button>
    );
};

export default ButtonSecondary;
